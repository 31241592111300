<template>
    <div class="container">
        <div class="partner_table">
            <div class="title">战略联盟
            </div>
            <div class="partner_list flex align_center">
                <div v-for="item in list" :key="item.p">
                    <p>{{item.p}}</p>
                    <img :src="'http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/'+item.img" alt="">
                    <div>
                        {{item.div}}
                    </div>
                </div>
            </div>
        </div>
        <div class="next_title">
            羽林数据，1000+用户的选择
        </div>
        <div class="jiesao">
            <img class="jiesao_img" src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/470f2098387702299482660046/f0.png" alt="">
            <img class="hide jiesao_img_mini" src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/470f213c387702299482660095/f0.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          p: '华为云',
          img: '470219e6387702299482659871/f0.png',
          div: '羽林数据是华为云第一个二类电商订单营销系统技术伙伴。'
        },
        {
          p: '企业微信',
          img: '42303c16387702299482433451/f0.png',
          div: '羽林数据成为企业微信在二类电商订单大数据营销领域唯一产品伙伴。'
        },
        {
          p: '中国移动',
          img: '427891ba387702299482473181/f0.png',
          div: '羽林数据成为中国移动企业用户推荐产品伙伴。'
        },
        {
          p: '顺丰速运',
          img: '42303755387702299482433315/f0.png',
          div: '羽林数据结盟顺丰公司推出 “羽林数据顺丰专版”。'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.partner_table {
  .title {
    padding-top: 126px;
    text-align: center;
    font-size: 36px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    margin-bottom: 60px;
  }
  .partner_list {
    justify-content: space-between;
    margin-bottom: 138px;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 204px;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 1px 5px 0px rgba(20, 49, 92, 0.2);
      border-radius: 10px;
      padding: 32px 18px 28px;
      p {
        font-size: 30px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
      }
      img {
        width: 98px;
      }
      div {
        font-size: 16px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
.next_title {
  font-size: 36px;
  font-family: GraublauWeb;
  font-weight: 400;
  color: #333333;
  margin-bottom: 62px;
  text-align: center;
}
.jiesao {
  padding-bottom: 130px;
  .jiesao_img {
    display: block;
  }
  .jiesao_img_mini{
      display: none
  }
}

@media all and (max-width: 1080px) {
  .partner_table {
    .partner_list > div {
      margin: 0 auto;
      margin-bottom: 30px;
    }
    .title {
      padding-top: 60px;
    }
  }

  .next_title {
    padding: 0 10px;
    font-size: 24px;
  }

  .jiesao_img {
    display: none !important;
  }

  .jiesao_img_mini {
    display: block !important;
    width: 100%;
  }
}
</style>